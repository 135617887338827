<template>
  <div class="head-container">
    <el-select
            v-model="query.ibRank"
            clearable
            placeholder="级别"
            class="filter-item"
            style="width: 150px"
            @change="toQuery"
    >
      <el-option
              v-for="item in ibRankOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select
            v-if="tableStatus=='-1'"
            v-model="query.auditStatus"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 150px"
            @change="toQuery"
    >
      <el-option
              v-for="item in ibStatusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input
            v-model="query.basicInfo"
            clearable
            placeholder="代理姓名/ID/手机/邮箱"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />
    <el-input v-model="query.managerInfo" clearable placeholder="客户经理姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <div class="filter-item">
      申请时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="filter-item">
      审核通过时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startAuditTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endAuditTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="toQuery"
    >搜索</el-button>
    <el-button
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-close"
            @click="clear"
    >清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import { mapGetters } from 'vuex'

export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'ibTypeOptions',
      'ibStatusOptions',
      'ibRankOptions'
    ])
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    }
  }
}
</script>
