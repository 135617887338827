<template>
  <div class="app-container">
    <eHeader
            :query="query"
            :tableStatus="tableStatus"
            @toQuery="toQuery"
            @clear="clear"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="代理ID" width="80"/>
      <el-table-column prop="userName" label="姓名"/>
      <el-table-column label="代理信息">
        <template slot-scope="scope">
          <span>{{ parseType(scope.row.custType) }}/{{ scope.row.levelName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ibRank" label="代理层级">
        <template slot-scope="scope">
          <span>{{ parseIbRank(scope.row.ibRank)}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="上级信息" width="150">
         <template slot-scope="scope">
            <div v-if="scope.row.belongId!=null">{{scope.row.belongName}}</div>
            <div v-if="scope.row.belongId!=null">{{scope.row.belongId}}<br></div>
            <div v-if="scope.row.belongId==null">无</div>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="联系方式" width="150">
         <template slot-scope="scope">
            <span v-if="scope.row.phone!=null">{{scope.row.phone}}<br></span>
            <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
            <span v-if="scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>
      <el-table-column v-if="tableStatus=='-1'" prop="auditStatus" label="代理状态">
        <template slot-scope="scope">
          <span>{{ parseIbStatus(scope.row.auditStatus)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="applyDate" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.applyDate) }}</span>
        </template>
      </el-table-column>
       <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.auditTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">

          <check
            class="my-button"
            v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_VIEW'])"
            :data="scope.row"
            :sup_this="sup_this"
            :isCheck="false"
            :tableStatus="tableStatus"
          />
            <check
                    class="my-button"
                    v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT'])&&visCheck"
                    :data="scope.row"
                    :sup_this="sup_this"
                    :isCheck="true"
                    :tableStatus="tableStatus"
            />
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
      <public-pagination
              :total-page="total"
              :current-page="page"
              @pageChange="pageChange"
              @sizeChange="sizeChange"
      ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { parseType,parseIbStatus,parseIbRank } from '@/views/pt/ib/utils/index'
import eHeader from './header'
import edit from './edit'
import check from '@/components/ib/merchantinfo/view'

export default {
  components: { eHeader, edit,check },
  mixins: [initData],
  props:{
    tableStatus: {
      type: Number,
      required: true
    },
    visCheck: {
      type: Boolean,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delLoading: false,
      sup_this: this
    }
  },
  watch:{
    refresh( newData,oldData){
        this.init()
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
    if(this.$store.getters.bussSectors==null){
       this.$store.dispatch('GetBussSectors')
    }
  },
  methods: {
    parseTime,
    parseType,
    checkPermission,
    parseIbStatus,
    parseIbRank,
    beforeInit() {
      this.url = '/crm/getCustInfoWithMTInfo'
      const sort = 'applyDate,desc'
      this.params = {
        custTypeStr:'1,2',
        page: this.page,
        size: this.size,
        sort: sort,
      }
      const query = this.query;
      const basicInfo = query.basicInfo;
      const ibRank = query.ibRank;
      const managerInfo = query.managerInfo;
      const auditStatus = query.auditStatus;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      if(this.tableStatus=='-1'){
        if(auditStatus!=null && auditStatus!=''){
          this.params['auditStatus'] = auditStatus;
        }
      }
      else if(this.tableStatus=='5') {
        this.params['auditStatus'] = '4';
        this.params['identifyStatus'] = '1';
        this.params['addressStatus'] = '1';
        this.params['bankStatus'] = '1';
        this.params['handleCertIdentifyStatus'] = '1';
      }
      else {
        this.params['auditStatus'] = this.tableStatus;
      }
      if (basicInfo!=null && basicInfo!='') { this.params['basicInfo'] = basicInfo }
      if (ibRank!='') { this.params['ibRank'] = ibRank }
      if (managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime };
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.page = 0;
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
